import React from 'react';
import TouchableOpacity from '@components/base/touchableOpacity';
import dispatchToApp from '@helpers/dispatchToApp';
import Title from './title';
import ConstructorElement, { ConstructorElementInterfaceS } from '@abstract/constructorElement';
import { $detailIsOpen } from '@effector/detail';

export interface P {
  title: string;
  appereance?: string;
  getProps: Function;
  hasBack?: any;
}


export class Header extends ConstructorElement<P, ConstructorElementInterfaceS> {
  private onClick = () => {
    this.log()
    dispatchToApp('close')
  }

  back = () => {
    if ($detailIsOpen.getState()) {
      const closer: any = document.querySelector('#detailCloser');
      if (closer) {
        closer.click()
      }
    }
    else
      this.props.getProps().history.push('/')
  }

  public render(): JSX.Element {
    const { title, appereance, hasBack } = this.props;

    return this._render(
      <>
      <div className="flex items-start px-4 relative min-h-10">
        {!!hasBack && (
          <div className="mr-4">
            <TouchableOpacity space={4} onClick={this.back}>
              <div className="fa fa-angle-left fa-2x text-primary" />
            </TouchableOpacity>
          </div>
        )}
        <div className="mr-12 pt-1 min-h-10">
          <Title type='title' title={this.parseValue(title, undefined, undefined, true)[0]} disabledMarginBottom disabledPaddings appereance={appereance} />
        </div>
      </div>
    </>
    )
  }
}

export default Header;
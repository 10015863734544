import React from 'react';
import Constructor, { ConfigInterface } from '@abstract/constructor';
import request from '@helpers/request';
import getParam from '@helpers/getParam';
import $global, { updateGlobal } from '@effector/global';
import Store from '@helpers/store';
import { ValueFromGet } from './constructorElement';
import parseString from '@helpers/parseString';
import Box from '@components/base/box';
import Preloader from '@components/base/preloader';
import ReactComponent from "@abstract/reactComponent";
import { combine } from 'effector';
import { connect } from 'effector-react';
import $disabledGlobalScroll from '@effector/disabledGlobalScroll';
import setClasses from '../helpers/setClasses';
import debug from '@helpers/debug';
import GITCONFIG from '../tmp/git-version.json';
import waitEvent from '@helpers/waitEvent';

export const GENERATOR: string = '/responses/generator.json'

export interface P {
  history: {
    push: Function
  }
}
export interface S {
  config: ConfigInterface | undefined;
  generated: Constructor | undefined;
  log?: string
  mode: any
}

interface PagesInterface {
  main: string
  shop: string
  detail: string
  generator?: string
}

export class Page<PP = {}, SS = {}> extends ReactComponent<PP & P, SS & S> {
  URLS: PagesInterface = {
    main: '',
    shop: '',
    detail: '',
    generator: ''
  }

  get config(): any {
    return {};
  }

  constructor(props: any) {
    super(props);
    this.state = {
      config: undefined,
      generated: undefined,
      log: '',
      mode: 'DEV',
    } as SS & S
  }

  async getParam(name: string) {
    let value: any = getParam(name);
    if (value) updateGlobal({ [name]: value })
    else {
      value = $global.getState()[name]
      if (value) { }
      else {
        value = await Store.get(name);
      }
    }

    return value || ''
  }

  async setConfig(): Promise<PagesInterface> {
    let URLS: PagesInterface = {
      main: '',
      shop: '',
      detail: '',
      generator: ''
    };

    this.URLS = {
      main: await this.getParam('main') || '/responses/main.json',
      shop: await this.getParam('shop') || '/responses/shop.json',
      detail: await this.getParam('detail') || '/responses/detail.json',
      generator: await this.getParam('generator') || GENERATOR,
    }

    return URLS;
  }

  public init: Function = async (config: ConfigInterface) => {
    return await this.setAsyncState({ config, generated: new Constructor(config, () => this.props) })
  }

  async toGlobal(config: ConfigInterface) {
    let state: any = {}
    config?.global?.forEach(async ({ name, value, type }) => {
      let _value: any = ValueFromGet(value);
      _value = parseString(_value, type);

      if (_value) {
        state[name] = _value
      }
      else {
        _value = await Store.get(name)
        _value = parseString(_value, type);
        if (_value) {
          state[name] = _value
        }
      }
    })

    updateGlobal(state);
    return state;
  }

  async componentDidMount(): Promise<any> {
    return new Promise((resolve) => {
      const redirect = getParam('redirect')
      if (redirect && redirect === 'shop') {
        Promise.all([
          waitEvent('storeready').run(),
          waitEvent('defaultConditions_done').run(),
          waitEvent('disableddates_done').run(),
          waitEvent('token_done').run(),
          waitEvent('user_done').run(),
          waitEvent('xvkusvilltoken_done').run(),
        ]).then(() => {
          this.props.history.push('/shop')
        })
      }

      this.setConfig().then(() => {
        const mode: any = Boolean(this.config.url?.search('.dev.') === -1) ? 'PROD' : 'DEV';
        if (window.location.href.search('localhost:3000') !== -1 && mode === 'PROD') {
          console.warn('App started wia prod env')
        }

        // clear calendar conditions for cart
        if (window.location.pathname === '/') {
          updateGlobal({ "calendarConditionsObj": null })
          updateGlobal({ "abilityCalendarDate": null })
        }

        this.setState({ mode })
        request({ eventName: 'constructor', method: 'get', ...this.config }, () => false).then(async (res: any) => {
          await this.toGlobal(res)

          await this.init(res);
          debug(JSON.stringify({ title: 'Page mounted', location: window.location.href }))

          resolve(null)
        })
      })
    })
  }

  static getStore(stores: any, Component: any) {
    const store: any = combine({ ...stores, disabledGlobalScroll: $disabledGlobalScroll });
    return connect(Component)(store)
  }

  _render() {
    const { generated } = this.state;
    const props: any = this.props;
    const { disabledGlobalScroll } = props;

    return (
      <div className={setClasses(['w-full h-full'], {
        'overflow-hidden': disabledGlobalScroll
      })}>
        <div className="bg-white w-full flex flex-col h-full overflow-auto">
          <div className="pt-3.5 flex flex-col h-full">
            {generated?.Header()}
            {generated?.Top()}
            {generated?.Content()}
            <Box />
            <div className="flex w-full justify-center items-center text-xs text-gray-200 p-2 uppercase">
              {this.state.mode} | {GITCONFIG.version}
            </div>
          </div>
          <Preloader />
        </div>
      </div>
    )
  }

  render() {
    return this._render()
  }
}

export default Page;

import ModalAbstract, { P, S, RenderInterface } from '@abstract/modal';
import dispatchToApp from '@helpers/dispatchToApp';
import { ReactNode } from 'react';
import Button from './button';

export interface AlertData {
  onClose?: Function
  title: string | ReactNode
  subTitle: string | ReactNode
  button?: {
    title: string
    onClick?: Function
    url: string
  },
  email: string
  order: string
  status: number | any
  description: {
    img: string
    alt: string
    text: string
  }[]
}

const insertStatus = (status: number | any) => {
  let textStatus = '';

  if (status === 0 || status === 2) {
    textStatus = 'Оплачен'
  }

   if (status === 1) {
    textStatus = 'Оформлен'
   }

   if (status < 0 || status === null) {
    textStatus = 'В процессе'
   }

  return `${textStatus}`
}

export class SubmitAlert extends ModalAbstract<AlertData, P<AlertData>, S<AlertData>> {
  _renderTitle(title?: string) {
    return (
      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center z-10">
        <div className="w-full px-4 text-xl">{title}</div>
      </div>
    )
  }

  _renderDescriptionLine(img: string, alt: string, text: string) {
    return (
      <div className="w-full flex flex-row items-center mb-4">
        <img src={img} alt={alt} className="w-12 mr-2" />
        <div className="text-sm" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    )
  }

  _renderContent({ children, title, backSettings, cancelSettings, controlsSettings }: RenderInterface): ReactNode {
    return (
      <>
        <div className="h-full">
          <div className="bg-alertBg pt-12 pb-8 mb-4 rounded-2xl">
            {this._renderTop({ children, title, backSettings, cancelSettings })}
            <div className="px-4">
              <div className="text-sm mb-2.5 mt-4">Мы отправили на вашу почту <b><span className="text-email">{this.state.data?.email}</span> письмо с деталями заказа.</b></div>
              <div className="bg-white shadow-sm rounded-2xl flex flex-row justify-between py-5 px-4 text-sm font-bold">
                <div>Заказ {this.state.data?.order}</div>
                <div className="text-primary">{insertStatus(this.state.data?.status)}</div>
              </div>
            </div>
          </div>
          {children}
        </div>
        {!!controlsSettings && this._renderControls(controlsSettings)}
      </>
    )
  }

  onClick() {
    dispatchToApp('browser', { url: this.state.data?.button?.url })
    if (this.state.data && this.state.data.button) {
      const { onClick } = this.state.data.button;
      if (onClick) onClick()
    }
  }

  render() {
    return this._render({
      title: this.state.data?.title,
      children: (
        <div className="px-4 pb-8">
          {this.state.data?.description?.map((item) => (
            this._renderDescriptionLine(item?.img, item?.alt, item?.text)
          ))}
          <div className="mb-4 text-base">{this.state.data?.subTitle}</div>
          {this.state.data?.button && <Button
            onClick={() => {
              this.onClick()
            }}
            isActive
            title={this.state.data?.button?.title}
            className="rounded-md py-3"
            touchableOpacityClassName="w-full"
            wrapperClassName="flex-1"
          />}
        </div>
      )
    })
  }
}

export default SubmitAlert